import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <Layout>
    <Seo title="Over ons" />
    <section className="page-header">
      <h1>Over ons</h1>
    </section>    
    <section className="page-intro">
      <div className="page-intro-content-rows">
        <p>Na meer dan 40 jaar aan het roer te staan van hun succesvolle optiekzaak gaan Tom en Marina De Bruyne samenwerken met Johan en Sanne. Zij hebben in Johan en Sanne betrouwbare partners gevonden, en kunnen op die manier met een gerust hart een stapje terugzetten.</p>
        <p>Tom en Marina blijven actief in de zaak, zij het iets meer achter de schermen naar de toekomst toe.</p>
        <p>Johan en Sanne zijn gediplomeerde opticiens, optometristen, contactlensspecialisten. Ze werken al meerdere jaren in de branche en brengen heel wat ervaring mee. U kan dus onverminderd blijven genieten van onze topservice op het vertrouwde adres recht tegenover de kerk in Hoogstraten</p>
      </div>
      <div className="col-start-1 md:col-start-7 lg:col-start-6 col-end-13 lg:col-end-12 row-start-1 row-end-1 z-10">
        <StaticImage
            src="../images/over-ons_team.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="inline-block mx-0 md:ml-8 lg:mx-2"
        />
      </div>
      <div className="section-bg-right col-start-6 md:col-start-9 lg:col-start-7 col-end-13 -top-16 -right-6 lg:right-4 h-full"></div>
    </section>

    <section className="section">
      <div className="section-content-right">
        <h2 className="pb-8 md:pb-28 xl:pb-40">
          Deskundig advies
        </h2>
        <p className="text-base my-4">Wij maken ons ook sterk door u een goede service aan te bieden.  U kunt steeds rekenen op ons deskundig advies en dankzij onze recente investeringen in ons oogmeetapparatuur kunnen wij tot 25x nauwkeurigere metingen uitvoeren en u zo het best mogelijke zicht garanderen.</p>
        <p className="text-base my-4">Neem daarbij dat wij ook alle soorten contactlenzen, sportbrillen en veiligheidsbrillen kunnen leveren waardoor wij kunnen inspelen op al uw wensen.</p>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-end">
        <StaticImage
            src="../images/over-ons_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
        {/* <StaticImage
            src="../images/lenzen_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        /> */}
      </div>
      <div className="section-bg-left"></div>
    </section>
</Layout>
)

export default AboutPage
